<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Steps</h2>
    </template>
    <template #page-content>
      <b-steps
        v-model="activeStep"
        :animated="true"
        :rounded="true"
        :has-navigation="true"
        label-position="bottom"
        mobile-mode="minimalist"
      >
        <b-step-item
          icon="account-key"
          label="Account"
          :clickable="isStepsClickable"
          type="is-primary"
        >
          <!-- untuk tombol -->
          <div class="level mb-0">
            <div class="level-left"></div>
            <div class="level-right">
              <b-button class="is-command is-dark" icon-left="plus">
                Save Draft
              </b-button>
            </div>
          </div>
          <!-- isi -->
          <r-card outlined class="mt-0">
            <div class="columns">
              <div class="column is-12">
                <r-board :columns="3">
                  <template #header>
                    <div class="level">
                      <div class="level-left">
                        <h3 class="board-title">Team Format</h3>
                      </div>
                      <div class="level-right">
                        <b-button class="is-command" icon-left="plus">
                          Additional Team
                        </b-button>
                      </div>
                    </div>
                  </template>
                  <template #default>
                    <r-ticket rounded-image>
                      <template #header>
                        <div class="level">
                          <div class="level-left"></div>
                          <div class="level-right">
                            <b-button
                              class="is-icon is-command"
                              icon-left="eye"
                              icon-size="is-medium"
                            ></b-button>
                            <b-button
                              class="is-icon is-command ml-0"
                              icon-left="plus"
                            ></b-button>
                          </div>
                        </div>
                      </template>
                      <template #image>
                        <img src="http://placehold.it/200x200" alt="" />
                      </template>
                      <template #default>
                        <h4 class="ticket-title mb-0">Business Analyst</h4>
                        <p class="is-size-6 mb-1 mt-1">1 Member</p>
                        <r-avatars :items="images"></r-avatars>
                      </template>
                    </r-ticket>
                    <r-ticket rounded-image disabled>
                      <template #header>
                        <div class="level">
                          <div class="level-left"></div>
                          <div class="level-right">
                            <b-button
                              class="is-icon is-command"
                              icon-left="eye"
                              icon-size="is-medium"
                            ></b-button>
                            <b-button
                              class="is-icon is-command ml-0"
                              icon-left="plus"
                            ></b-button>
                          </div>
                        </div>
                      </template>
                      <template #image>
                        <img src="http://placehold.it/200x200" alt="" />
                      </template>
                      <template #default>
                        <h4 class="ticket-title mb-0">Business Analyst</h4>
                        <p class="is-size-6 mb-1 mt-1">1 Member</p>
                        <r-avatars :items="images"></r-avatars>
                      </template>
                    </r-ticket>
                    <r-ticket rounded-image disabled>
                      <template #header>
                        <div class="level">
                          <div class="level-left"></div>
                          <div class="level-right">
                            <b-button
                              class="is-icon is-command"
                              icon-left="eye"
                              icon-size="is-medium"
                            ></b-button>
                            <b-button
                              class="is-icon is-command ml-0"
                              icon-left="plus"
                            ></b-button>
                          </div>
                        </div>
                      </template>
                      <template #image>
                        <img src="http://placehold.it/200x200" alt="" />
                      </template>
                      <template #default>
                        <h4 class="ticket-title mb-0">Business Analyst</h4>
                        <p class="is-size-6 mb-1 mt-1">1 Member</p>
                        <r-avatars :items="images"></r-avatars>
                      </template>
                    </r-ticket>
                    <r-ticket rounded-image disabled>
                      <template #header>
                        <div class="level">
                          <div class="level-left"></div>
                          <div class="level-right">
                            <b-button
                              class="is-icon is-command"
                              icon-left="eye"
                              icon-size="is-medium"
                            ></b-button>
                            <b-button
                              class="is-icon is-command ml-0"
                              icon-left="plus"
                            ></b-button>
                          </div>
                        </div>
                      </template>
                      <template #image>
                        <img src="http://placehold.it/200x200" alt="" />
                      </template>
                      <template #default>
                        <h4 class="ticket-title mb-0">Business Analyst</h4>
                        <p class="is-size-6 mb-1 mt-1">1 Member</p>
                        <r-avatars :items="images"></r-avatars>
                      </template>
                    </r-ticket>
                    <r-ticket rounded-image disabled>
                      <template #header>
                        <div class="level">
                          <div class="level-left"></div>
                          <div class="level-right">
                            <b-button
                              class="is-icon is-command"
                              icon-left="eye"
                              icon-size="is-medium"
                            ></b-button>
                            <b-button
                              class="is-icon is-command ml-0"
                              icon-left="plus"
                            ></b-button>
                          </div>
                        </div>
                      </template>
                      <template #image>
                        <img src="http://placehold.it/200x200" alt="" />
                      </template>
                      <template #default>
                        <h4 class="ticket-title mb-0">Business Analyst</h4>
                        <p class="is-size-6 mb-1 mt-1">1 Member</p>
                        <r-avatars :items="images"></r-avatars>
                      </template>
                    </r-ticket>
                    <r-ticket rounded-image disabled>
                      <template #header>
                        <div class="level">
                          <div class="level-left"></div>
                          <div class="level-right">
                            <b-button
                              class="is-icon is-command"
                              icon-left="eye"
                              icon-size="is-medium"
                            ></b-button>
                            <b-button
                              class="is-icon is-command ml-0"
                              icon-left="plus"
                            ></b-button>
                          </div>
                        </div>
                      </template>
                      <template #image>
                        <img src="http://placehold.it/200x200" alt="" />
                      </template>
                      <template #default>
                        <h4 class="ticket-title mb-0">Business Analyst</h4>
                        <p class="is-size-6 mb-1 mt-1">1 Member</p>
                        <r-avatars :items="images"></r-avatars>
                      </template>
                    </r-ticket>
                    <r-ticket rounded-image disabled>
                      <template #header>
                        <div class="level">
                          <div class="level-left"></div>
                          <div class="level-right">
                            <b-button
                              class="is-icon is-command"
                              icon-left="eye"
                              icon-size="is-medium"
                            ></b-button>
                            <b-button
                              class="is-icon is-command ml-0"
                              icon-left="plus"
                            ></b-button>
                          </div>
                        </div>
                      </template>
                      <template #image>
                        <img src="http://placehold.it/200x200" alt="" />
                      </template>
                      <template #default>
                        <h4 class="ticket-title mb-0">Business Analyst</h4>
                        <p class="is-size-6 mb-1 mt-1">1 Member</p>
                        <r-avatars :items="images"></r-avatars>
                      </template>
                    </r-ticket>
                  </template>
                </r-board>
              </div>
            </div>
          </r-card>
        </b-step-item>

        <b-step-item
          icon="account"
          label="Profile"
          :clickable="isStepsClickable"
        >
          <div class="level mb-0">
            <div class="level-left"></div>
            <div class="level-right">
              <b-button class="is-command is-dark" icon-left="plus">
                Save Draft
              </b-button>
            </div>
          </div>
          <code>
            <div>ok</div>
          </code>
          <r-card outlined class="mt-0">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
            repellendus similique quas accusamus, ipsa aliquam repudiandae sequi
            sunt quaerat optio, ullam, unde distinctio recusandae sapiente animi
            at quibusdam ipsum eveniet!
          </r-card>
        </b-step-item>

        <b-step-item
          icon="account-plus"
          :visible="showSocial"
          label="Social"
          :clickable="isStepsClickable"
        >
          <h1 class="title has-text-centered">Social</h1>
          Lorem ipsum dolor sit amet.
        </b-step-item>

        <b-step-item
          :step="showSocial ? '4' : '3'"
          label="Finish"
          :clickable="isStepsClickable"
          disabled
        >
          <h1 class="title has-text-centered">Finish</h1>
          Lorem ipsum dolor sit amet.
        </b-step-item>

        <template #navigation="{ previous, next }">
          <div class="level step-footer">
            <div class="level-left">
              <b-button
                type="is-disabled"
                :disabled="previous.disabled"
                @click.prevent="previous.action"
              >
                Cancel
              </b-button>
            </div>
            <div class="level-right">
              <b-button
                  type="is-primary"
                :disabled="next.disabled"
                @click.prevent="next.action"
              >
                Save & Next
              </b-button>
            </div>
          </div>
        </template>
      </b-steps>
    </template>
  </r-page>
</template>
<script>
export default {
  data() {
    return {
      images: [
        // ini bentuk struktur data images yang bisa dipakai (salah satu aja)
        // struktur 1
        {
          image: 'https://picsum.photos/200/300',
          name: 'Jane Doe',
        },
        {
          // struktur 2, kalau tidak ada image maka akan memunculkan initial
          name: 'John Doe',
          backgroundColor: 'red',
        },
        // struktur 3, array of images
        'https://picsum.photos/200/300',
        'https://picsum.photos/200/200',
        'https://picsum.photos/200/200',
        'https://picsum.photos/200/200',
      ],
      activeStep: 0,

      showSocial: false,
      isStepsClickable: true,

      hasNavigation: true,
    }
  },
}
</script>
